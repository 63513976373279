import http from "@/utils/request";

export function getShopInfo(data) {
  return http({
    method: "get",
    url: "/custbase/getCustBaseInfo.nd?custId=" + data,
  });
}

// 主子账号
export function getsonList(data) {
  return http({
    method: "post",
    contentType: "application/json;charset=UTF-8",
    type: 'stringfy',
    url: "/custbase/getMasterSonAccountList.nd",
    data: data
  });
}

// /custbase/getCooperationNum.nd
export function getNum(data) {
  return http({
    method: "post",
    contentType: "application/json;charset=UTF-8",
    type: 'stringfy',
    url: "/custbase/getCooperationNum.nd",
    data: data
  });
}

// 获取服务商
export function findProviderList(data) {
  return http({
    method: "post",
    // contentType: "application/json;charset=UTF-8",
    // type: 'stringfy',
    url: "serviceProvider/findProviderList.nd",
     data:data
  });
}
// 获取市接口
export function getRegion(data) {
  return http({
    method: "get",
    // contentType: "application/json;charset=UTF-8",
    // type: 'stringfy',
    url: "/customer/getRegion.nd",
    params:data
  });
}

// 根据乡镇查询门店
export function findHisenseShopListByTownCode(data) {
  return http({
    method: "post",
    contentType: "application/json;charset=UTF-8",
    type: 'stringfy',
    url: "/custbasePermission/findHisenseShopListByTownCode.nd",
    data:data
  });
}

// 保存新建账号时某市下管辖区域的绑定门店信息
export function saveAccountManageShop(data) {
  return http({
    method: "post",
    contentType: "application/json;charset=UTF-8",
    type: 'stringfy',
    url: "/custbasePermission/saveAccountManageShop.nd",
    data:data
  });
}
// 删除
export function deleteRegionBinding(data) {
  return http({
    method: "DELETE",
    // contentType: "application/json;charset=UTF-8",
    // type: 'stringfy',
    url: "/custbasePermission/deleteRegionBinding.nd",
    params:data
  });
}
//点击行编辑 查询当前数据
export function editRegionBindingOne(data) {
  return http({
    method: "get",
    // contentType: "application/json;charset=UTF-8",
    // type: 'stringfy',
    url: "/custbasePermission/editRegionBinding.nd",
    params:data
  });
}
// 清缓存
export function flushAccountRegionShop(data) {
  return http({
    method: "post",
    contentType: "application/json;charset=UTF-8",
    type: 'stringfy',
    url: "/custbasePermission/flushAccountRegionShop.nd",
    data:data
  });
}
// /
export function getRegionBindInfo(data) {
  return http({
    method: "get",
    url: "/custbasePermission/getRegionBindInfo.nd",
    params:data
  });
}