
import http from "@/utils/request";
export function getAccountInfo(data) {
    return http({
        method: "get",
        url: "/customer/customerAccount.nd",
        data
    });
}
// 权限接口
export function MaterialList(data) {
    return http({
      method: "post",
      contentType: "application/json;charset=UTF-8",
      type: 'stringfy',
      url: "/custbasePermission/getCustBasePermissionDataByCustId.nd",
      data: data
    });
  }
  export function savePermission(data) {
    return http({
      method: "post",
      contentType: "application/json;charset=UTF-8",
      type: 'stringfy',
      url: "/custbasePermission/saveCustBasePermissionList.nd",
      data: data
    });
  }
export function edit(data) {
    return http({
        method: "POST",
        url: "/customer/editEnterpriseUser.nd",
        params: data
    });
}
// 获取岗位
export function getdictInfo(data) {
    return http({
        method: "get",
        url: "/comm/dict.nd?pid=" + 14181287560,
    });
}
// 获取没绑定网格区域
export function getNoBound(data) {
  return http({
      method: "get",
      url: "/grid/checkAccountAllotGrid.nd?gridManager=" + data,
  });
}
// 获取仓库
//获取通知
export function getmessageInfo(data) {
    return http({
        method: "get",
        url: "/comm/dict.nd?pid=" + '101000',
    });
}

// 获取待办

export function getdaiabnInfo(data) {
    return http({
        method: "get",
        url: "/comm/dict.nd?pid=" + '102000',
    });
}

// 获取角色 仓库
export function getRole(data) {
    return http({
        method: "post",
        contentType: "application/json;charset=UTF-8",
        type: 'stringfy',
        url: "/custbasePermission/getCustBasePermissionDataByCustId.nd",
        data: data
    });
}

// 查询当前账号门店信息
export function editRegionBinding(data) {
  return http({
    method: "post" ,
    contentType: "application/json;charset=UTF-8",
    type: 'stringfy',
    url: "/custbasePermission/getRegionBindingByAccount.nd",
     data
  });
}
// 查询当前账号门店信息
export function editRegionBindingNew(data) {
  return http({
    method: "post" ,
    contentType: "application/json;charset=UTF-8",
    type: 'stringfy',
    url: "/custbasePermission/getRegionBindingByCache.nd",
    data
  });
}
























































