
import http from "@/utils/request";
export function getAccountInfo(data) {
    return http({
        method: "get",
        url: "/customer/customerAccount.nd",
        data
    });
}
export function edit(data) {
    return http({
        method: "POST",
        url: "/customer/editEnterpriseUser.nd",
        params: data
    });
}
// 获取岗位
export function getdictInfo(data) {
    return http({
        method: "get",
        url: "/comm/dict.nd?pid=" + 14181287560,
    });
}
// 获取仓库
//获取通知
export function getmessageInfo(data) {
    return http({
        method: "get",
        url: "/comm/dict.nd?pid=" + '101000',
    });
}

// 获取待办

export function getdaiabnInfo(data) {
    return http({
        method: "get",
        url: "/comm/dict.nd?pid=" + '102000',
    });
}

// 获取角色 仓库
export function getRole(data) {
    // 之前的接口
    // return http({
    //     method: "post",
    //     contentType: "application/json;charset=UTF-8",
    //     type: 'stringfy',
    //     url: "/custbasePermission/getCustBasePermissionDataByCustId.nd",
    //     data: data
    // });
    // 2/1号接口修改
    return http({
        method: "post",
        contentType: "application/json;charset=UTF-8",
        type: 'stringfy',
        url: "/custbasePermission/getCustBaseRoleDataByCustId.nd",
        data: data
    });
}

// 获取账号
export function getAccount(data) {
    return http({
        method: "get",
        url: "/customer/getAccountData.nd",
        data
    });
}


// 获取单独物料
export function getmarket(data) {
    return http({
        method: "get",
        url: "/customer/getMatklAndShopData.nd",
        data
    });
}



// 获取账号(新)
export function getAccountNum(data) {
    return http({
        method: "get",
        url: "/customer/getAccount.nd",
        data
    });
}


// 获取门店
export function findShopList(data) {
    return http({
        method: "POST",
        url: "/custbasePermission/findShopList.nd",
        contentType: "application/json;charset=UTF-8",
        type: 'stringfy',
        data
    });
}
















































