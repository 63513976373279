<template>
  <a-modal
    v-model="visible"
    @ok="handleOk"
    @cancel="cancle"
    wrapClassName="cancelName"
    :width="648"
    :closable="false"
    centered
    :maskClosable="false"
  >
    <div class="modal_close" @click="cancle">
      <span class="iconfont icon-danchuangguanbi-anniu modal_close_icon"></span>
    </div>
    <p style="font-size: 12px;margin-bottom:24px">提示：非下沉渠道网格经理已有负责网格，岗位的变更需要经过营销中心办事处审批，请选择审批人。</p>
    <div class="cancelContent">
      <p><span class="red">*</span><span class="text">营销中心：</span></p>
      <a-select
        style="width: 270px" placeholder="请输入"  v-model="infoForm.marketCenterIds"  :filterOption="filterOption">
        <a-select-option
          v-for="item of selectData.marketCenter"
          :key="item.code"
          :value="item.code"
        >{{item.name}}</a-select-option>
      </a-select>

    </div>
    <div class="cancelContent">
      <p><span class="red">*</span><span class="text">办事处经理：</span></p>
      <a-select
        :filterOption="false"
        placeholder="请输入(建议输入拼音+数字)"
        :value="infoForm.principalName"
        show-search
        @search="principalInput"
        @change="principalChange"
        style="width: 270px"
      >
        <a-select-option v-for="(item, idx) of principal" :key="idx">{{ item.name }}</a-select-option>
      </a-select>

    </div>
  </a-modal>
</template>
<script>
import { getSlt } from "@/views/basicInformation/components/businessAddEdit/api";
import { getPrincipal } from "@/views/distributionStores/addDistributionStore/api";

export default {
  name:'',
  data(){
    return{
      fetching:false,
      submitBtn:true,
      selsetClear:false,
      list:[],
      isEdit:false,
      reason:'',
      textarea:'',
      FXDLlnum: '', // 模糊搜索 代理商id
      FXDLlist: [], // 模糊搜索出来的数据
      form: this.$form.createForm(this, { name: 'advanced_search' }),
      selectData:{},
      infoForm:{
        marketCenterIds:undefined, // select 绑定的value初始值 不为undefined时 会不显示placeholder
        principalName:undefined, // select 绑定的value初始值 不为undefined时 会不显示placeholder
        ldapUserId:''
      },
      principal: [], //办事处经理

    }
  },
  props:{
    visible:{
      type:Boolean,
      default:false
    },
  },
  mounted(){
    this.getOffice()
  },
  methods:{
    principalInput(e) {
      let data = {
        searchstr: e,
      };
      getPrincipal(data).then((res) => {
        if (res.data.code == 0) {
          this.principal = res.data.list;
        }
      });
    },
    // 办事处经理改变
    principalChange(e) {
      this.infoForm.ldapUserId = this.principal[e].code;
      this.infoForm.principalName = this.principal[e].name;
      this.infoForm = { ...this.infoForm };
    },
    getOffice() {
      //获取下拉
      getSlt().then((sltres) => {
        this.$nextTick(() => {
          //下拉数据
          this.selectData = sltres.data;
        });
      });
    },
    //是否根据输入项进行筛选
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    //查询数据
    search() {},

    handleOk(){

      if(!this.infoForm.marketCenterIds){
        this.$message.warning('请选择营销中心')
        return
      }
      if(!this.infoForm.ldapUserId){
        this.$message.warning('请选择办事处经理')
        return
      }
      this.$emit('update:visible',false)
      this.$emit('cancelReasonHandOk',this.infoForm)

    },
    cancle(){
      this.$emit('update:visible',false)

    },

  }
}
</script>
<style lang="less" scoped>
/deep/ .cancelName{
  .ant-modal-content{
    text-align:center;
    border-radius: 10px;
    .ant-modal-body{
      display:flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .cancelContent{
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
        color: #262626;
        line-height: 20px;
        p{
          font-size: 14px;
          width: 94px;
          text-align: right;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-end;
          .red{
            color: #D9161C;
          }
        }

        .ant-select-auto-complete.ant-select .ant-input {
          width: 270px;
        }

      }
      .cancelContent:last-child{
        margin-top: 24px;
        margin-bottom: 40px;
      }
      .modal_close {
        position: absolute;
        top: 0;
        right: 0;
        width: 42px;
        height: 42px;
        line-height: 42px;
        border-radius: 0 10px 0 0;
        text-align: center;
        cursor: pointer;
        .modal_close_icon {
          color: #aaa;
          font-size: 16px;
        }
      }
      .modal_close:hover {
        background: #E4F8F7;
        .modal_close_icon {
          color: #08ABA8;
        }
      }
      .modal_title_box {
        text-align: center;
        padding-bottom:50px;
        .modal_title {
          position: relative;
          color: #262626;
          text-align: center;
          z-index: 10;
          font-size: 16px;
          font-weight: 600;
          line-height: 22px;
          letter-spacing:1px;
          .modal_title_line {
            display: inline-block;
            width: 100%;
            position: absolute;
            top: 13px;
            left: 0;
            border-top: 10px solid #E4F8F7;
            z-index: -10;
          }
        }
      }
    }
    .ant-modal-footer{
      text-align: center;
      border-top:0px solid #fff;
      padding:0px 0 30px;
      .ant-btn{
        width: 120px;
        height:40px;
        border-radius: 4px;
        font-size: 16px;
        color:#777;
      }
      .ant-btn-primary{
        color: #fff;
        background:#00AAA6;
        margin-left:24px;
      }
    }
  }
}
</style>


