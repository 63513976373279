<template>
  <div>

      <a-modal
      v-model="visible"
      @ok="handleOk"
      @cancel="cancle"
      wrapClassName="cancelNameTack"
      :width="1188"
      :closable="false"
      centered
      :maskClosable="false"
      :footer="null"
    >

        <div class="modal_close" @click="cancle">
        <span class="iconfont icon-danchuangguanbi-anniu modal_close_icon"></span>
      </div>
          <div class="modal_title_box">
            <span class="modal_title">
              <i class="modal_title_line"></i>分配管辖区域和管辖门店
            </span>
          </div>
          <p class="title"><span>*</span>管辖区域</p>
          <a-spin :spinning="isLoading">
            <div class="areaBox">
              <div class="everyBox"
                   v-for="(item,index) in areaInfo"
                   :key="index"
              >
                <div class="leftBox">
                  <p class="cityName">{{item.provinceName}}{{ item.cityName }}</p>
                   <p class="total">合计：已选<a>{{item.checkedShopNum}}</a>个</p>
                </div>
                <p class="opreator">
      <!--            <span @click="edit(item)"><img src="@/assets/baseInfo/xq.png"/>详情</span>-->
                  <span v-if="!showPopUp" @click="edit(item)"><img src="@/assets/baseInfo/bj.png"/>编辑</span>
                  <span v-if="!showPopUp" @click="del(item)"><img src="@/assets/baseInfo/sc.png"/>删除</span>
                  <span class="gray" v-if="showPopUp"><i class="iconfont icon-zhinengtianxie"></i>编辑</span>
                  <span class="gray" v-if="showPopUp"><i class="iconfont icon-shanchu"></i>删除</span>

                </p>
              </div>
              <a-button class="addbutton" v-if="!showPopUp" @click="continueAdd('continue')" >+继续添加</a-button>
            </div>
            <div v-if="this.areaInfo.length<=0 && this.type == 'add'">
              <div class="contentBox" v-show="showPopUp">
                <div class="addBox">
                  <div class="leftBox">
                    <div class="cityBox">
                      <div class="leftInfoBox">
                        <div class="address-wrap">
                          <a-select
                            style="width:180px"
                            :placeholder="'请输入城市'"
                            class="chose-account"
                            show-search
                            @search="getCityList"
                            option-filter-prop="children"
                            :filter-option="filterOption"
                            @change="handleChangeCityList"
                            v-model="manageAreaObj.cityCode"
                            dropdownMatchSelectWidth
                          >
                            <a-select-option
                              :value="item.cityCode"
                              v-for="item in cityList"
                              :key="item.cityCode"
                            >{{ item.cityName }}</a-select-option
                            >
                          </a-select>
                          <div class="quBox">
                            <p
                              v-for="item in manageAreaObj.manageAreaList"
                              :key="item.districtCode"
                              @click="queryBindingStatus(item)"
                              :class="item.checkClass"
                            >
                              <span >
                                <span  style="overflow:hidden;white-space: nowrap;text-overflow: ellipsis;width:130px;"  :title="item.districtName">{{item.districtName}}<span v-if="item.bindNum>0">(已选{{item.bindNum}})</span></span>
                              </span>
                              <span v-if="item.checkClass=='quNameActive2'"><a-icon type="check" /></span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="leftInfoBox rightInfoBox">
                        <div class="address-wrap">
                          <p class="top" :title="showName">{{showName}}</p>
                          <div class="quBox">
      <!--                      :class="item.checked?'quNameActive':'quName'"-->
                            <p
                              v-for="item in townList"
                              :key="item.townCode"
                              :class="item.checkClass"
                              @click="changeTown(item)"
                            >

                              <span :title="item.townName" style="overflow:hidden;white-space: nowrap;text-overflow: ellipsis;">{{item.townName}}<span v-if="item.bindNum>0">(已选{{item.bindNum}})</span></span>
                              <span v-if="item.checkClass=='quNameActive2'"><a-icon type="check" /></span>
                            </p>
                          </div>
                          <a-checkbox class="check" v-show="townList.length>0" v-model="allCheckBtn" @click="allCheck">全选</a-checkbox>
                        </div>
                      </div>
                    </div>
                    <div class="streetBox">
                    </div>
                  </div>
                  <!--          :row-selection="rowSelection()"-->
                  <div class="tableForm">
                    <a-table
                      style="margin-left: 24px;width:692px"
                      :scroll="{y:454}"
                      :columns="tcolumns"
                      :data-source="tableData"
                      :pagination="false"
                      :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
                      rowKey="shopInfoId"
                    >
                    </a-table>
                    <div  style="text-align: left;margin:16px 0 0 24px;color: #777">共{{tableData?tableData.length:0}}条,已选{{chooseNum}}条</div>
                    <!--            已选{{}}条-->
                  </div>
                </div>
              </div>
              <div class="ant-modal-footer" v-show="showPopUp" >
                <h-btn
                  :width="120"
                  :height="40"
                  content="退出编辑"
                  class="edit"
                  @click="cancelEdit"
                ></h-btn>
                <h-btn
                  :width="120"
                  :height="40"
                  content="暂存"
                  class="sumbit"
                  @click="save"
                ></h-btn>
              </div>
            </div>
            <div v-else>
              <div class="contentBox" v-show="showPopUp">
                <div class="addBox">
                  <div class="leftBox">
                    <div class="cityBox">
                      <div class="leftInfoBox">
                        <div class="address-wrap">
                          <a-select
                            style="width:180px"
                            :placeholder="'请输入城市'"
                            class="chose-account"
                            show-search
                            @search="getCityList"
                            option-filter-prop="children"
                            :filter-option="filterOption"
                            @change="handleChangeCityList"
                            v-model="manageAreaObj.cityCode"
                            dropdownMatchSelectWidth
                          >
                            <a-select-option
                              :value="item.cityCode"
                              v-for="item in cityList"
                              :key="item.cityCode"
                            >{{ item.cityName }}</a-select-option
                            >
                          </a-select>
                          <div class="quBox">
                            <p
                              v-for="item in manageAreaObj.manageAreaList"
                              :key="item.districtCode"
                              :class="item.checkClass"
                              @click="queryBindingStatus(item)"
                            >
                              <span >
                                <span  style="overflow:hidden;white-space: nowrap;text-overflow: ellipsis;width:130px;"  :title="item.districtName">{{item.districtName}}<span v-if="item.bindNum>0">(已选{{item.bindNum}})</span></span>
                              </span>
                              <span v-if="item.checkClass=='quNameActive2'"><a-icon type="check" /></span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="leftInfoBox rightInfoBox">
                        <div class="address-wrap">
                          <p class="top" :title="showName">{{showName}}</p>
                          <div class="quBox">
      <!--                      :class="item.checked?'quNameActive':'quName'"-->
                            <p
                              v-for="item in townList"
                              :key="item.townCode"
                              :class="item.checkClass"
                              @click="changeTown(item)"
                            >
                              <span :title="item.townName"  style="overflow:hidden;white-space: nowrap;text-overflow: ellipsis;">{{item.townName}}<span v-if="item.bindNum>0">(已选{{item.bindNum}})</span></span>
                              <span v-if="item.checkClass=='quNameActive2'"><a-icon type="check" /></span>
                            </p>
                          </div>
                          <a-checkbox class="check" v-show="townList.length>0"  v-model="allCheckBtn" @click="allCheck">全选</a-checkbox>
                        </div>
                      </div>
                    </div>
                    <div class="streetBox">
                    </div>
                  </div>
                  <!--          :row-selection="rowSelection()"-->
                  <div class="tableForm">
                    <a-table
                      style="margin-left: 24px;width:692px"
                      :scroll="{y:454}"
                      :columns="tcolumns"
                      :data-source="tableData"
                      :pagination="false"
                      :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
                      rowKey="shopInfoId"
                    >
                    </a-table>
                    <div  style="text-align: left;margin:16px 0 0 24px;color: #777">共{{tableData?tableData.length:0}}条,已选{{chooseNum}}条</div>
                    <!--            已选{{}}条-->
                  </div>
                </div>
              </div>
              <div class="ant-modal-footer" v-show="showPopUp" >
                <h-btn
                  :width="120"
                  :height="40"
                  content="退出编辑"
                  class="edit"
                  @click="cancelEdit"
                ></h-btn>
                <h-btn
                  :width="120"
                  :height="40"
                  content="暂存"
                  class="sumbit"
                  @click="save"
                ></h-btn>
              </div>
            </div>
          </a-spin>

          <delete-modal
            :visible.sync="visibleDeldistrict"
            :info="'取消区县将同时删除此区县下的全部门店，是否确认删除？'"
            @deleteHandOk="deldistrict"
          ></delete-modal>
          <delete-modal
            :visible.sync="visibleDel"
            :info="'取消街道将同时删除此街道下的全部门店，是否确认删除？'"
            @deleteHandOk="delect"
          ></delete-modal>
          <delete-modal
            :visible.sync="visibleDelShop"
            :info="'确认删除此区域以及门店？'"
            @deleteHandOk="delShop"
          ></delete-modal>
          <message-modal
            :visible.sync="shopMessage"
            :info='messageInfo'
          ></message-modal>
          <delete-modal
            :visible.sync="bindVisible"
            :info="msgInfo"
            @deleteHandCancle="delMd"
            :type ="confirm"
          ></delete-modal>
          <delete-modal
            :visible.sync="unbindingVisible"
            :info="msgInfo"
            @deleteHandOk="unbinding"
          ></delete-modal>

    </a-modal>

  </div>
</template>
<script>

import {
  deleteRegionBinding,
  findHisenseShopListByTownCode,
  getRegion,
  saveAccountManageShop,
  editRegionBindingOne, getRegionBindInfo
} from "@/views/basicInformation/api";
import { editRegionBinding, editRegionBindingNew } from "@/views/basicInformation/components/editPerson/api";
export default {
  components:{
  },
  name: "mangeStore",
  data() {
    return {
      isLoading:false,
      districtRow:{},
      delIds:[],
      msgInfo:'',
      unbindingVisible: false,
      visibleDeldistrict: false,
      areaInfo: [], // 回显数据
      visibleDelShop: false, //删除头部行数据弹窗
      shopMessage: false,
      visibleDel: false, //取消街道弹窗
      addOredit: false, //下面编辑省市区显示隐藏
      nolistImg: require("@/assets/order/none.png"), //无数据
      cityName: '', //所选区的name 展示用
      cityId: undefined, //所选区的id 查询区
      selRows: [], //选的门店数据
      selectedRowKeys: [], //选择的门店的shopInfoId
      chooseNum:0,
      tcolumns: [
        {
          title: '门店名称',
          dataIndex: 'shopFullName',
          width:150,
          ellipsis: true,
        },
        {
          title: '门店标签',
          dataIndex: 'shopTag',

        },
        {
          title: '是否绑定海信系',
          dataIndex: 'isBindingedTxt',

        },
        {
          title: '已绑海信系账号',
          dataIndex: 'bindingAccount',
        },
      ], //门店table的表头

      showName: '', //展示的去名称
      manageAreaObj: { //传给后台树结构
        provinceName:'',
        regionId:'',
        cityName:'',
        cityCode:undefined,
        manageAreaList:[]
      },
      allCheckBtn: false,
      showPopUp: false, // 添加门店弹窗
      townList: [], // 街道
      tableData: [], //mendian
      chooseTowmId:'',
      delId: '',
      cityList:[],
      districtId: '',
      checkClass: '',
      msg:[],
      bindVisible:false

    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    taskId: {
      type: Number,
    },
    taskName: {
      type: String,
    },
    positionIdList: {
      type: Array,
      required: true
    },
    matklIdList: {
      type: Array,
      required: true
    },
    type: {
      type: String,
    },
    accountName: {
      type: String,
    },
    accountHisenseType: {
      type: Number,
    },
    changeNum: {
      type: Number,
    },
  },
  watch: {
    townList: {
      // allCheckBtn
      immediate: true,
        handler(newValue) {
        this.allCheckChange()

      },
      deep:true
    },
    tableData:{
      immediate: true,
      handler(newValue) {
        if(this.tableData&&this.tableData.length>0) {
          this.tableData.forEach(item=>{
            item.isBindingedTxt = item.isBindinged == 0?'未绑':'已绑'
          })
          this.allCheckChange()
        }


      },
      deep:true
    },
    selectedRowKeys:{
      immediate: true,
      handler(newValue) {
        if (this.manageAreaObj.manageAreaList.length > 0){
          this.manageAreaObj.manageAreaList.forEach(it => {
            let count = 0;
            if(it.townList) {
              it.townList.forEach(item=>{
                item.bindNum = 0
                if(item.shopList) {
                  item.shopList.forEach(shopIt =>{
                    if(this.selectedRowKeys.includes(shopIt.shopInfoId)){
                      shopIt.checked = true
                    } else {
                      shopIt.checked = false
                    }
                    if(shopIt.checked) {
                     
                      item.bindNum+=1
                      // if(item.bindNum > 0) {
                      //   console.log(shopIt)
                      //   item.checkClass = 'quNameActive'
                      // }
                    }
                  })
                }
                if(item.shopList && item.shopList.length > 0) {
                  count = count + item.shopList.filter(numdata => numdata.checked).length
                }


              })
            }
            //
            it.bindNum = count?count:0
          })
          this.getNewChooseNum()
        }


      },
      deep:true
    },
    //
    visible: {
      handler(val) {
        if(val){
          this.getInfo()
          // if(this.changeNum > 1) {
          //   this.getNewInfo()
          // } else {
          //
          // }

        }

      },
      immediate: true,
    },
    'manageAreaObj.manageAreaList': {
      handler(val) {
        if(val){
          val.forEach(item => {
            if(!item.checked) {
              item.checkClass = 'quName'
            }
          })
        }
        }
    }
  },
  created() {

  },
  methods: {
    // 全选
   async allCheck(e) {
       if(e.target.checked){
         this.allCheckBtn = e.target.checked
       }
      // 全选 选中状态复制
      if(e.target.checked){
        this.chooseTowmId = this.townList[0].townCode
        this.manageAreaObj.manageAreaList.forEach(el=> {
          if( this.districtId == el.districtCode) {
            if( el.townList) {
              el.townList.forEach(async(item) => {
                item.checked = true
                let data = {
                  countyCodeList:[item.townCode],
                  positionIdList:this.positionIdList,
                  matklIdList:this.matklIdList
                }
                await findHisenseShopListByTownCode(data).then(res=>{
                  let shopListRes = res.data.list
                  item.shopList = shopListRes
                  this.$nextTick(()=>{
                    this.townList = el.townList
                
                    this.generateStreetCheckClass()
                    this.townList.forEach(item=>{
                      item.checkClass = 'quNameActive2'
                    })
                    if(this.townList.length>0){
                      this.isLoading = true
                      // 获取到门店数据大于0的 下标 
                      let index = this.townList.findIndex(it=>(it.shopList&&it.shopList.length>0))
                      if(index>0) {
                        //  给显示的table赋值
                        this.townList[index].checkClass='quNameActive'
                        this.tableData =this.townList[index].shopList
                      } else {
                        this.townList[0].checkClass='quNameActive'
                        this.tableData =this.townList[0].shopList
                      }
                      
                      this.getNewChooseNum()
                      this.isLoading = false
                      
                    }
                  })
                  this.$set(item,'shopList',shopListRes)
                  this.$forceUpdate()
                  if(res.data.code == 0 && res.data.list.length>0) {
                    shopListRes.forEach(it=> {
                      // this.accountHisenseType 1 海信系 0是未绑  1是已绑
                      // 17594638392 下沉渠道经理
                      // == -1 就是不存在
                      // 如果是海信系账号
                      if(this.accountHisenseType == 1 ) {
                        // 如果是下沉门店 全选全部
                        if((this.positionIdList.indexOf(17594638392) > -1 || this.positionIdList.indexOf('17594638392') > -1) ){
                          it.checked = true
                          this.selectedRowKeys.push(it.shopInfoId)
                        } else if(it.isBindinged === 1 && (it.bindingAccount &&it.bindingAccount.includes(this.accountName) )) { // 如果是门店包含当前账号并且是已绑全部
                          it.checked = true
                          this.selectedRowKeys.push(it.shopInfoId)
                        } else {
                          // 其余为未绑的 push全部
                          if(it.isBindinged === 0) {
                            it.checked = true
                            this.selectedRowKeys.push(it.shopInfoId)
                          }
                        }
                      } else {
                        // 如果是非海信系 不需要区分 全部绑定
                        it.checked = true
                        this.selectedRowKeys.push(it.shopInfoId)
                      }

                    })
                  }
                })
              })
             
            }
          }
        })
        // this.generateStreetCheckClass()
        // this.townList.forEach(item=>{
        //   item.checkClass = 'quNameActive2'
        // })
        // if(this.townList.length>0){
        //   this.isLoading = true
        //   let index = this.townList.findIndex(it=>(it.shopList&&it.shopList.length>0))
        //   console.log(index)
        //   this.townList[index].checkClass='quNameActive'
        //    this.tableData =this.townList[index].shopList
        //   this.getNewChooseNum()
        //   this.isLoading = false
        //   // let townCode =''
        //   // this.manageAreaObj.manageAreaList.forEach(el=> {
        //   //   if( this.districtId == el.districtCode) {
        //   //     townCode = el.townList[index].townCode
        //   //   }
        //   // })
        //   // findHisenseShopListByTownCode({
        //   //   countyCodeList:[townCode],
        //   //   positionIdList:this.positionIdList,
        //   //   matklIdList:this.matklIdList
        //   // }).then(res=>{
        //   //   this.tableData = res.data.list
        //   //   this.getNewChooseNum()
        //   //   this.isLoading = false
        //   // }).catch(error=>{
        //   //   this.isLoading = false
        //   // })
        // }

      } else {
        this.tableData =[]
        let delArr = []
        this.chooseTowmId = ''
        let findeIndex = this.manageAreaObj.manageAreaList.findIndex(item => item.districtCode == this.districtId)
        let findeTwonIndex = this.manageAreaObj.manageAreaList[findeIndex].townList.findIndex(item => item.checked)
        if(findeIndex > -1) {
          this.manageAreaObj.manageAreaList[findeIndex].townList.forEach(item=>{
            item.checked = false
            item.checkClass =  'quName'
            if(item.shopList&&item.shopList.length >0 ){
              item.shopList.forEach( (shopItem,index) => {
                shopItem.checked = false
                // shopItem.isBindinged 未绑数据的shopId
                delArr.push(shopItem.shopInfoId)
                // if(shopItem.isBindinged === 0){
                //
                // }
              })
            }
          })
          this.selectedRowKeys = this.getArrDifference(this.selectedRowKeys, delArr )
          this.townList = this.manageAreaObj.manageAreaList[findeIndex].townList
        }
        
        // if(findeTwonIndex >= 0) {
        //   this.chooseTowmId = this.manageAreaObj.manageAreaList[findeIndex].townList[findeTwonIndex].townCode
        //   this.tableData = this.manageAreaObj.manageAreaList[findeIndex].townList[findeTwonIndex].shopList
        // } else {
        //   this.tableData = []
        // }
        this.getNewChooseNum()
      }
    },
    // 点击删除按钮 弹出提示 获取当前行id 用于确认删除调用删除接口
    del(item) {
      this.visibleDelShop = true
      this.delId = item.regionId
    },
    // 确认删除此数据
    delShop() {
      let data = {
        accountId:this.accountName,
        cityRegionId:this.delId
      }
      deleteRegionBinding(data).then(res=>{
        if(res.data.code  == 0){
          this.$message.success('删除成功')
          this.getNewInfo()
          // this.areaInfo.push(...res.data.list)
          this.showPopUp = false
          this.manageAreaObj = {
            provinceName:'',
            regionId:'',
            cityName:'',
            cityCode:undefined,
            manageAreaList:[]
          }
          this.selectedRowKeys = []
          this.townList = []
          this.tableData = []
          this.showName = ''
          this.allCheckBtn = false
        }
      })
    },
    //获取区县
    async getCityList(val) {
      if(this.type == 'add'&&!val) {
        this.manageAreaObj.cityName = this.$store.state.user.userInfo.customer.currentCityName
      }else {
        this.manageAreaObj.cityName = val
      }
      let data  =  {
        searchCityName: this.manageAreaObj.cityName,
        code: '',
        type: ''
      }
      await getRegion(data).then(res => {
        this.cityList = res.data
        this.townList = []
        this.selectedRowKeys = []
        this.tableData = []
        this.showName = ''
        if (this.type == 'add'&&!val) {
          let obj =  this.cityList.filter(it=>it.cityCode == this.$store.state.user.userInfo.customer.currentCityCode )
          this.manageAreaObj = {
            regionId: obj?obj[0].id:'',
            cityCode: obj?obj[0].cityCode:undefined,
            cityName: obj?obj[0].cityName:'',
            provinceName: obj?obj[0].provinceName:'',
            manageAreaList: []
          }
        }
      }).catch(error=>{
      })
      if (this.type =='add') {
        let dataCity = {
          searchCityName: '',
          code: this.manageAreaObj.cityCode ,
          type: '2'
        }
        // 获取区县  新增数据没有之前存储的门店直接获取当前账号的区县 省 去查询区域
        const response = await this.getDiuction(dataCity)
        // 赋值区
        this.manageAreaObj = { ... this.manageAreaObj, manageAreaList: response.data }
        this.manageAreaObj.manageAreaList.forEach(item => {
          item.checked = false
          item.bindNum = 0
        })
      }
    },
    // 修改城市
    handleChangeCityList(value, e) {
      this.cityName = value;
      this.chooseNum = 0
      this.cityId = e.key;
      let obj =  this.cityList.filter(it=>it.cityCode == this.cityId)
      let cityId = this.areaInfo.map(it=>{
        return it.regionId
      })
      // 如果选择已有城市 弹出提示
      if(cityId.includes(obj[0].id)) {
        this.shopMessage = true
        this.messageInfo = `${obj[0].cityName}已经添加,请选择其他城市`
        this.manageAreaObj = {
          regionId: '',
          cityCode: undefined,
          cityName: '',
          provinceName: '',
          manageAreaList: []
        }
        return
      }
      this.manageAreaObj = {
        regionId: obj?obj[0].id:'',
        cityCode: obj?obj[0].cityCode:undefined,
        cityName: obj?obj[0].cityName:'',
        provinceName: obj?obj[0].provinceName:'',
        manageAreaList: []
      }
      let data  =  {
        searchCityName:'',
        code:this.cityId,
        type:'2'
      }
      getRegion(data).then(res =>{
        this.manageAreaObj = { ... this.manageAreaObj, manageAreaList: res.data }
        this.manageAreaObj.manageAreaList.forEach(item => {
          item.checked = false
          item.bindNum = 0
        })
        this.getNewChooseNum()
      })
    },
    // 获取乡镇
    getDiuction(data) {
      return getRegion(data)
    },
    generateCheckClass() {
      this.manageAreaObj.manageAreaList.forEach(item => {
        if (item.checked) {
          if ((item.districtCode === this.districtId) ) {
            item.checkClass = 'quNameActive'
          } else if (item.bindNum > 0) {
            item.checkClass = 'quNameActive2'
          }
          else {
            item.checkClass = 'quName'
          }
        } else {
          item.checkClass = 'quName'
        }
      })
    },
    // 点击确定
    unbinding() {
       this.changeDistrict( this.districtRow )
    },
    // 查询绑定状态
    queryBindingStatus(item) {
     if((!this.positionIdList.includes(17594638392) && !this.positionIdList.includes('17594638392')) && this.accountHisenseType == 1 ) {
         this.districtRow = item
         if((item.isWhite != null && !item.isWhite) && !item.checked) {
           let msg = []
           let accountNum = 0
           //  调接口 查询区域绑定状态
           getRegionBindInfo({regionId:item.id}).then(res=>{
             if(res.data.code == 0 && res.data.list.length > 0  ) {
               res.data.list.forEach(item => {
                 if(item.account != this.accountName && item.categorySeries == 1){
                   msg.push(item.account)
                   accountNum += 1
                 }
               })
               if(accountNum>0) {
                 this.unbindingVisible = true
                 msg = msg.join(',')
                 this.msgInfo = `该区县仅可绑定一个海信系账号，且已经被${msg}绑定管辖，如解绑区县将解绑其下已绑门店。是否确认更改。`
               } else {
                 this.changeDistrict(item)
               }
             } else {
               this.changeDistrict(item)
             }
           })
         } else {
           this.changeDistrict(item)
         }
       } else {
         this.changeDistrict(item)
       }
    },
    // 点击区域 获取乡镇
    changeDistrict(item) {
      let titleFlag = false
      if (item.checkClass !== 'quName' && item.checkClass !== 'quNameActive2') {
        titleFlag = true
      }
      item.checked = true
      this.districtId = item.districtCode
      this.generateCheckClass()
      // 展示删除弹窗
      this.visibleDeldistrict = titleFlag
      this.chooseNum = 0
      this.getNewChooseNum()
      this.showName = item.districtName
      if( item.checked ) {
        let dataCity = {
          searchCityName: '',
          code: item.districtCode,
          type: '3'
        }
        this.tableData = []
        this.manageAreaObj.manageAreaList.forEach(async(el) => {
          // 如果区县匹配 并且当前数据没有乡镇 就掉接口赋值 如果之前有就不调用接口
          if(el.districtCode == item.districtCode) {
            if(el.townList &&  el.townList.length >0) {
              this.townList = el.townList
              let index = this.townList.findIndex(it=>it.bindNum>0)
              if(index > -1) {
                this.chooseTowmId = this.townList[index].townCode
                this.tableData = this.townList[index].shopList;
              } 
              this.generateStreetCheckClass()
             } else {
              // 获取区县  新增数据没有之前存储的门店直接获取当前账号的区县 省 去查询区域
              const response = await this.getDiuction(dataCity)
              // 赋值区
              this.townList = response.data
              el.townList = this.townList
              el.townList.forEach(it => {it.shopList = [],it.bindNum = 0,it.checkClass='quName'})
            }
          }
        //   如果绑定数量小于0 下面的乡镇都置为false
         if(el.bindNum <= 0 ){
          if( el.townList && el.townList.length > 0 ) {
              el.townList.forEach(item => {
              if(item.bindNum<=0) {
                item.checked = false
                item.checkClass='quName'
              }
            })
          }
         }
        
        })
      }
      this.getNewChooseNum()
      this.$forceUpdate()
    },
    // 删除区县
    deldistrict(){
      //  获取当前要删除的数据的下标
      let index = this.manageAreaObj.manageAreaList.findIndex(item => item.districtCode == this.districtId)
      let selectedDistrict = this.manageAreaObj.manageAreaList[index]
      selectedDistrict.checked = false
      selectedDistrict.checkClass = 'quName'
      selectedDistrict.bindNum = 0
      let shopIds = []
      selectedDistrict.townList.forEach(townItem => {
        townItem.checked = false
        townItem.checkClass = 'quName'
        if(townItem.shopList && townItem.shopList.length > 0) {
          townItem.shopList.forEach(el => {
            el.checked = false
            shopIds.push(el.shopInfoId)
          })
        }

      })
      this.selectedRowKeys = this.getArrDifference(this.selectedRowKeys, shopIds)
      this.showName = ''
      // 获取当前数据的第一个选中的 把数据重置回第一个选中状态
      let findeIndex = this.manageAreaObj.manageAreaList.findIndex(item => item.checked)
      if (findeIndex > -1) {
        this.districtId = this.manageAreaObj.manageAreaList[findeIndex].districtCode
        this.manageAreaObj.manageAreaList[findeIndex].checkClass='quNameActive'
        this.manageAreaObj.manageAreaList[findeIndex].townList[0].checkClass='quNameActive'
        this.townList = this.manageAreaObj.manageAreaList[findeIndex].townList
        let findeTwonIndex = this.manageAreaObj.manageAreaList[findeIndex].townList.findIndex(item => item.checked)
        if (findeTwonIndex > -1) {
          this.chooseTowmId = this.manageAreaObj.manageAreaList[findeIndex].townList[findeTwonIndex].townCode
          this.tableData = this.manageAreaObj.manageAreaList[findeIndex].townList[findeTwonIndex].shopList
        }
        this.getNewChooseNum()
      } else {
        this.districtId = ''
        this.townList = []
        this.tableData = []

      }
    },
    generateStreetCheckClass() {
      this.manageAreaObj.manageAreaList.forEach(el=> {
        if( el.townList) {
          el.townList.forEach(item => {
            if (item.checked) {
              if (item.townCode === this.chooseTowmId) {
                item.checkClass = 'quNameActive'
              } else if (item.bindNum >= 0 || item.bindNum) {
                item.checkClass = 'quNameActive2'
              }
              else {
                item.checkClass = 'quName'
              }

            } else {
              item.checkClass = 'quName'
            }
          })
        }
      })
    },
      // 修改街道 查询门店
    async changeTown(item) {
      this.chooseTowmId = item.townCode

      // 展示删除弹窗
      let titleFlag = false
      if (item.checkClass !== 'quName' && item.checkClass !== 'quNameActive2') {
        titleFlag = true
      }
      this.visibleDel = titleFlag
      item.checked = true

      this.generateStreetCheckClass()
      let data = {
        countyCodeList:[item.townCode],
        positionIdList:this.positionIdList,
        matklIdList:this.matklIdList
      }
      this.chooseNum = 0
      let findeIndex = this.manageAreaObj.manageAreaList.findIndex(item => item.districtCode ==  this.districtId)
      let currentlySelectedData = this.manageAreaObj.manageAreaList[findeIndex].townList.filter(it=>it.townCode === this.chooseTowmId)
      if(currentlySelectedData.length>0){
        currentlySelectedData.forEach(item=>{
          if(item.townCode === this.chooseTowmId){
            if(item.shopList && item.shopList.length>0) {
              this.tableData = item.shopList
              this.getNewChooseNum()
            }else {
              findHisenseShopListByTownCode(data).then(respons=>{
                this.tableData =  respons.data.code == 0 ? respons.data.list : []
                item.shopList =  respons.data.code == 0 ? respons.data.list : []
                this.getNewChooseNum()
              })
            }
          }
        })
        this.generateStreetCheckClass()
      }
    },
    // 确定取消街道以及门店的选择
    async delect() {
      for (let i = 0; i < this.townList.length; i++) {
        let item = this.townList[i];
        if (item.townCode == this.chooseTowmId) {
          item.checked = false
          item.checkClass =  'quName'
          this.$forceUpdate()
          this.allCheckBtn = false
          break
        }
      }
      let newshopIds = []
      await findHisenseShopListByTownCode({
        countyCodeList:[this.chooseTowmId],
        positionIdList:this.positionIdList,
        matklIdList:this.matklIdList
      }).then(respons => {
         respons.data.list.forEach(it => {
           newshopIds.push(it.shopInfoId)
        })
      })
      this.selectedRowKeys = this.getArrDifference(this.selectedRowKeys, newshopIds)
      let findeIndex = this.manageAreaObj.manageAreaList.findIndex(item => item.districtCode == this.districtId)
      let findeTwonIndex = this.manageAreaObj.manageAreaList[findeIndex].townList.findIndex(item => item.checked)
      if(findeTwonIndex >= 0) {
        this.townList = this.manageAreaObj.manageAreaList[findeIndex].townList
        this.chooseTowmId = this.manageAreaObj.manageAreaList[findeIndex].townList[findeTwonIndex].townCode
        this.tableData = this.manageAreaObj.manageAreaList[findeIndex].townList[findeTwonIndex].shopList
      } else {
        this.tableData = []
      }
      this.getNewChooseNum()
      // this.getBoundNum()
      this.allCheckChange()

    },
    getArrDifference(arr1, arr2) {
      if (!arr1 || arr1.length === 0) {
        return []
      }
      if (!arr2 || arr2.length === 0) {
        return arr1;
      }
      let array = []
      arr1.forEach(item => {
        if (!arr2.includes(item)) {
          array.push(item)
        }
      })
      return array;
    },
    exist(num, arr1) {
      for (var j = 0; j < arr1.length; j++) {
        if (num === arr1[j]) {
          return false; //如果传过来的元素在arr1中能找到相匹配的元素，我们返回fasle
        }
      }
      return true; //如果不能找到相匹配的元素，返回true
    },
    // 判断是否是下沉门店
    isSunkenStore() {
       return this.positionIdList.includes(17594638392) || this.positionIdList.includes('17594638392')
    },
    // 选中的门店的id
    onSelectChange(selectedRowKeys,record) {
      // record 当前行
      this.selectedRowKeys = selectedRowKeys;
      // this.accountHisenseType 海信系 0是未绑  1是已绑
      // 17594638392 下沉渠道经理
      // 非海信系  不处理
      // 海信系 不是下沉  谈提示
      // 海信系 是下沉 不处理
      // 海信系 不是下沉 选择的门店和自己一样  不提示

      if(this.accountHisenseType == 1 && !this.isSunkenStore() ){
        this.delIds  = []
        this.msg = []
        // let accountNum = 0
        record.forEach(item=>{
          // 已绑而且是未选中 判断是否是自己绑定的
          if(item.isBindinged == 1 && item.checked == false) {
            if(!item.bindingAccount.includes(this.accountName)) {
              this.delIds.push(item.shopInfoId)
              // 门店解绑
              this.msg.push(`${item.shopFullName}已与${item.bindingAccount}绑定`)
            }
            // item.bindAccount = item.bindAccount.join(',')
            // if(item.bindAccount.forEach(it=>{
            //   if(it.account != this.accountName){
            //     accountNum += 1
            //   }
            // }))
            // if(accountNum > 0) {
            //  }
          }
        })
        // 提示信息  并且he当前账号不一致
        if( this.msg.length > 0 ){
          this.bindVisible = true
          this.msgInfo =  this.msg.join(',') + '是否确认更改?'
        }
      }
    },
    delMd() {
      this.selectedRowKeys = this.getArrDifference(this.selectedRowKeys, this.delIds )
    },
    // 获取已绑数量
    getBoundNum() {
      this.manageAreaObj.manageAreaList.forEach(districtItem => {
        // 区县的街道
        if(districtItem.townList) {
          // 门店
          if(districtItem.shopList) {
            districtItem.shopList.forEach(shopItem => {
              shopItem.checked = false
              // 区县街道的code 等于 当前选择的
              this.selectedRowKeys.forEach(RowKeyItem => {
                if(shopItem.shopInfoId == RowKeyItem) {
                  shopItem.checked = true
                }
              })
            })
            // 已绑定数量
            districtItem.bindNum = districtItem.shopList.filter(it => it.checked).length?districtItem.shopList.filter(it => it.checked).length:0
          }
        }
      })
    },
    //数组去重方法二
     removeRepeat02(arr) {
       var hash = {},
        len = arr.length,
        str_mark = '',
            result = [];
        for (var i = 0; i < len; i++){
            //判断数组中是否存在字符串
            if(typeof arr[i]=='string'){
                str_mark = '_isStr';
            }else {
                str_mark = '';
            }
            //去重
            if (!hash[arr[i] + str_mark]){
                hash[arr[i] + str_mark] = true;
                result.push(arr[i]);
            }
        }
        return result;
    },
    // 获取最新已选择数量
   async getNewChooseNum() {
      if( this.tableData.length <= 0 ){
        this.chooseNum = 0
        return
      }
      let newArr = []
      if( this.selectedRowKeys && this.selectedRowKeys.length > 0){
        this.selectedRowKeys.forEach((item,it)=>{
          if(newArr.indexOf(item) == -1){
            newArr[newArr.length] = this.selectedRowKeys[it]
          }
        
        })
        // let newArr = this.removeRepeat02(this.selectedRowKey)
      }
      let nowChecked = 0
    
      if(this.tableData && this.tableData.length>0){
        this.tableData.forEach(item => {
          newArr.forEach(it => {
            if(item.shopInfoId == it) {
              nowChecked += 1
            }
          })
        })
        // 当前表格已选数量
        this.chooseNum = nowChecked
      } else {
        this.chooseNum = 0
      }

    },
    async getShopAPI() {
      let checkTownrow = this.townList.filter(it => {
        return it.checked
      })
      // 获取选中的数据的id
      let checkTownId = checkTownrow.map(it => {
        return it.townCode
      })
      let data = {
        countyCodeList:checkTownId,
        positionIdList:this.positionIdList,
        matklIdList:this.matklIdList
      }
      return findHisenseShopListByTownCode(data)
    },
    // 获取曲线
    getTown (townCode) {
      let data = {
        searchCityName: '',
        code: this.manageAreaObj.cityCode ,
        type: '3'
      }
      return getRegion(data)
    },
    continueAdd(type) {
      this.type = type
      this.cityList = ''
      this.showPopUp = true
      this.manageAreaObj = {
        provinceName:'',
        regionId:'',
        cityName:'',
        cityCode:undefined,
        manageAreaList:[]
      }
    },
    cancle() {
      this.$emit("update:visible", false);
      this.showPopUp = false
      this.manageAreaObj = {
        provinceName:'',
        regionId:'',
        cityName:'',
        cityCode:undefined,
        manageAreaList:[]
      }
      this.selectedRowKeys = []
      this.townList = []
      this.tableData = []
      this.showName = ''
      this.allCheckBtn = false
    },
    cancelEdit() {
      this.showPopUp = false
      this.manageAreaObj = {
        provinceName:'',
        regionId:'',
        cityName:'',
        cityCode:undefined,
        manageAreaList:[]
      }
      this.selectedRowKeys = []
      this.cityCode = undefined
      this.manageAreaObj.cityCode = undefined
      this.townList = []
      this.tableData = []
      this.showName = ''
      this.allCheckBtn = false
      this.chooseNum = 0
    },
    // 提交
    save() {
      if(this.manageAreaObj.manageAreaList&&this.manageAreaObj.manageAreaList.length > 0) {
        this.manageAreaObj.manageAreaList.forEach( item => {
          if(item.bindNum <= 0) {
            item.checked = false
          } 
          if(item.bindNum > 0){
            item.checked = true
          }
        })
      }
      this.manageAreaObj.account = this.accountName
      saveAccountManageShop(this.manageAreaObj).then(res=>{
        if(res.data.code == 0){
          this.areaInfo = []
          this.areaInfo.push(...res.data.data)
          this.showPopUp = false
          this.manageAreaObj = {
            provinceName:'',
            regionId:'',
            cityName:'',
            cityCode:undefined,
            manageAreaList:[]
          }
          this.selectedRowKeys = []
          this.townList = []
          this.tableData = []
          this.showName = ''
          this.allCheckBtn = false
        } else {
          this.$message.warning(res.data.msg)
        }
      })
      this.$emit('goBack', JSON.parse(JSON.stringify(this.positionIdList)))

    },
    // 点击编辑和详情调用 打开下面编辑省市区门店
    async edit(item) {
      this.isLoading = true
      this.chooseNum = 0
      this.selectedRowKeys = []
      let data  =  {
        searchCityName:  item.cityName,
        code: '',
        type: ''
      }
     await getRegion(data).then(res => {
       this.cityList = res.data
       let obj =  this.cityList.filter(it=>it.id == item.regionId )
       this.manageAreaObj.cityCode = obj.cityCode
       this.manageAreaObj = {
         regionId: item.regionId,
         cityCode: obj[0].cityCode,
         cityName: item.cityName,
         provinceName: item.provinceName,
       }
       this.isLoading = false
      }).catch(error=>{
       this.isLoading = false
     })

      // 查询之前的数据
      await editRegionBindingOne({
        accountId:this.accountName,
        cityCode:item.regionId
      }).then(res=> {
        if (res.data.code == 0 && res.data.data) {
          this.manageAreaObj = { ...this.manageAreaObj, manageAreaList: res.data.data }
          let findeIndex = this.manageAreaObj.manageAreaList.findIndex(item => item.checked)
          this.districtId = this.manageAreaObj.manageAreaList[findeIndex].districtCode
          if (findeIndex > -1) {
            this.townList = this.manageAreaObj.manageAreaList[findeIndex].townList
            let findeTwonIndex = this.manageAreaObj.manageAreaList[findeIndex].townList.findIndex(item => item.checked)
            if (findeTwonIndex > -1) {
              this.chooseTowmId = this.manageAreaObj.manageAreaList[findeIndex].townList[findeTwonIndex].townCode
              this.tableData = this.manageAreaObj.manageAreaList[findeIndex].townList[findeTwonIndex].shopList
            }
          }
          this.manageAreaObj.manageAreaList.forEach(dirItem => {
            if (dirItem.townList) {
              dirItem.townList.forEach(townItem => {
                if (townItem.shopList) {
                  townItem.shopList.forEach(shopItem => {
                    if (shopItem.checked) {
                      this.selectedRowKeys.push(shopItem.shopInfoId)
                    }
                  })
                }
              })
            }
          })
          this.generateCheckClass()
          this.generateStreetCheckClass()
          // this.getBoundNum()
          // this.getNewChooseNum()
        }
      })
      this.editId = item.regionId
      this.showPopUp = true
    },
    //判断全选按钮
    allCheckChange() {
      let checkNum = 0
      this.townList.forEach(it=>{
        if(it.checked){
          checkNum += 1
        }
      })
      if( checkNum>0 && (checkNum == this.townList.length) ) {
        this.allCheckBtn = true
        // this.allCheck()
      } else {
        this.allCheckBtn = false
      }
    },
    getNewInfo() {
      this.isLoading = true
      this.areaInfo = []
      let data = {
        accountId:this.accountName
      }
      editRegionBindingNew( data ).then (res=> {
        if(res.data.code == 0 && res.data.list.length > 0 ) {
          this.areaInfo = []
          res.data.list.forEach(item => {
            this.areaInfo.push(item )
          })
        } else {
          this.areaInfo = []
        }
        if( this.areaInfo.length > 0 ) {
          this.showPopUp = false
        }
        this.isLoading = false
      }).catch(error=>{
        this.isLoading = false
      })
    },
    // 获取之前的信息  编辑页面
    getInfo() {
      this.isLoading = true
      this.areaInfo = []
      let data = {
        accountId:this.accountName,
      }
      editRegionBindingNew( data ).then (res=> {
        if(res.data.code == 0 && res.data.list.length > 0 ) {
          this.areaInfo = []
          res.data.list.forEach(item => {
            this.areaInfo.push(item )
          })
        } else {
          this.areaInfo = []
        }
        if( this.areaInfo.length > 0 ) {
          this.showPopUp = false
        }
        this.isLoading = false
      }).catch(error=>{
        this.isLoading = false
      })
      if(this.areaInfo.length<=0 && this.type == 'add') {
        this.areaInfo = []
        this.showPopUp = true
        this.getCityList()
      }
      this.isLoading = false
    },

  }
};
</script>
<style lang="less" scoped>
/deep/ .cancelNameTack {
  .ant-modal-content {
    text-align: center;
    border-radius: 10px;

    .ant-modal-body {
      align-items: center;
      justify-content: flex-start;
      display: flex;
      flex-direction: column;
      padding:30px 24px;
      padding-bottom: 24px;
      min-height: 755px;

      .modal_close {
        position: absolute;
        top: 0;
        right: 0;
        width: 42px;
        height: 42px;
        line-height: 42px;
        border-radius: 0 10px 0 0;
        text-align: center;
        cursor: pointer;

        .modal_close_icon {
          color: #aaa;
          font-size: 16px;
        }
      }

      .modal_close:hover {
        background: #E4F8F7;

        .modal_close_icon {
          color: #08ABA8;
        }
      }

      .modal_title_box {
        text-align: center;
        padding-bottom: 34px;

        .modal_title {
          position: relative;
          color: #262626;
          text-align: center;
          z-index: 10;
          font-size: 16px;
          font-weight: 600;
          line-height: 22px;
          letter-spacing: 1px;

          .modal_title_line {
            display: inline-block;
            width: 100%;
            position: absolute;
            top: 13px;
            left: 0;
            border-top: 10px solid #E4F8F7;
            z-index: -10;
          }
        }
      }
      .title {
        text-align: left;
        width: 176px;
        height: 22px;
        font-size: 16px;
        font-weight: 500;
        color: #262626;
        line-height: 22px;
        margin-bottom: 12px;
        width:100%;
        span{
          color: #D9161C;
        }
      }
      //.contentBox::-webkit-scrollbar {
      //  width: 0;
      //}
      .areaBox{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        .everyBox{
          width: 1140px;
          height: 54px;
          background: linear-gradient(263deg, #FBFBFB 0%, #EEF9F9 100%);
          border-radius: 6px;
          margin-bottom: 12px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          padding:0 24px;
          .leftBox{
            display: flex;
            flex-direction: row;
            .cityName{
              font-size: 14px;
              font-weight: 500;
              color: #262626;
            }
            .total{
              margin-left:50px;
              font-size: 14px;
              font-weight: 400;
              color: #777777;
              a{
                cursor: none;
                color: #00AAA6;
              }
            }
          }
          .opreator{
            display: flex;
            flex-direction: row;
            span{
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: flex-end;
              margin-left:20px;
              color: #1890FF;
              cursor: pointer;
              img{
                width: 16px;
                height: 16px;
                margin-right: 6px;
              }
            }
            .gray{
              color: #777;
              cursor: not-allowed;
              i{
                margin-right: 6px;
              }
            }
          }
        }
        .addbutton{
          width: 388px;
          height: 32px;
          border-radius: 6px;
          border: 1px dashed #D9D9D9;
          background-color: #fff;
          margin-top: 4px;
          color: #1890FF;
          margin: 4px auto 0  ;

        }
        .addbutton:hover{
          color: #1890FF;
          border: 1px dashed #1890FF;
          background: rgba(24, 144, 255, 0.08);
        }
      }
      .contentBox {
        height: 546px;
        //overflow-y: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width:100%;
        .addBox{
          height: 546px;
          display: flex;
          justify-content: flex-start;
          width:100%;
          .leftBox{
            width: 424px;
            height: 544px;
            background: #F2F3F5;
            border-radius: 6px;
            .cityBox{
              text-align: left;
              padding:20px;
              display: flex;
              flex-direction: row;
              position: relative;
              height: 100%;
              .leftInfoBox{
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                flex-direction: column;
                position: relative;
                .quBox{
                  height: 447px;
                  overflow-y: auto;


                  .quName{
                    width: 180px;
                    height: 32px;
                    background: #FFFFFF;
                    border-radius: 4px;
                    border: 1px solid #EEEEEE;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    color:#262626;
                    margin-top: 8px;
                    cursor: pointer;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    padding:0 14px;
                    span {
                      height:20px;
                    }
                  }
                  .quNameActive{
                    width: 180px;
                    height: 32px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    background: #00AAA6;
                    color: #FFFFFF;
                    border-radius: 4px;
                    padding:0 14px;
                    margin-top: 8px;
                    cursor: pointer;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    span {
                      height:20px;
                    }
                  }
                  .quNameActive2{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-top: 8px;
                    width: 180px;
                    height: 32px;
                    background: #FFFFFF;
                    color: #00AAA6;
                    padding:0 14px;
                    border-radius: 4px;
                    cursor: pointer;
                    overflow:hidden;
                    text-overflow:ellipsis;//文本溢出显示省略号
                    white-space:nowrap;//文本不会换行
                    border: 1px solid rgba(0,170,166,0.4);
                    span {
                      height:20px;
                    }
                  }
                }
                .quBox::-webkit-scrollbar {
                  width: 0;
                }
                .address-wrap{
                  .check{
                    position: absolute;
                    bottom: 0px;
                  }
                }
              }
              .rightInfoBox{
                margin-left:24px;
                position: relative;
                .top{
                  width: 180px;
                  height: 32px;
                  //display: flex;
                  //flex-direction: column;
                  //align-items: flex-start;
                  //justify-content: center;
                  //margin-left: 14px;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;;
                }
                .address-wrap{

                  .check{
                    position: absolute;
                    bottom: 0px;
                  }
                }
                .quBox{
                  height: 447px;
                  overflow-y: auto;
                  .quName{
                    width: 180px;
                    height: 32px;
                    background: #FFFFFF;
                    border-radius: 4px;
                    border: 1px solid #EEEEEE;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    color:#262626;
                    margin-top: 8px;
                    cursor: pointer;
                    padding:0 14px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    span {
                      height:20px;
                    }
                  }
                  .quNameActive{
                    width: 180px;
                    height: 32px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    background: #00AAA6;
                    color: #FFFFFF;
                    border-radius: 4px;
                    padding:0 14px;
                    margin-top: 8px;
                    cursor: pointer;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    span {
                      height:20px;
                    }
                  }
                  .quNameActive2{
                    overflow:hidden;
                    text-overflow:ellipsis;//文本溢出显示省略号
                    white-space:nowrap;//文本不会换行
                    width: 180px;
                    height: 32px;
                    background: #FFFFFF;
                    color: #00AAA6;
                    justify-content: space-between;
                    padding:0 14px;
                    cursor: pointer;
                    border-radius: 4px;
                    border: 1px solid rgba(0,170,166,0.4);
                    span {
                      height:20px;
                    }
                  }
                }
                .quBox::-webkit-scrollbar {
                  width: 0;
                }

              }
            }
          }
          .tableForm{
            display: flex;
            flex-direction: column;
          }
        }
      }

    }


    .ant-modal-footer {
      text-align: center;
      border-top: 0px solid #fff;
      padding:30px 0 0px;
      .edit{
        width: 120px;
        height: 40px;
        border-radius: 4px;
        background: #fff;
        border: 1px solid #CCCCCC;
        color: #777777;
        text-shadow: none;
        box-shadow: none;
      }
      .sumbit{
        width: 120px;
        height: 40px;
        background: #00AAA6;
        border-radius: 4px;
        text-shadow: none;
        box-shadow: none;
        margin-left: 24px;
      }
    }
  }
}
</style>


